
<div>
	<button
			title=""
			class="swv-button swv-button-trigger btn btn-primary text-white"
			:disabled="disabled || !wallet || connecting || connected"
			@click="(e) => onClick(e)"
	>
		<wallet-icon v-if="wallet" :wallet="wallet"></wallet-icon>
		<span v-text="content"></span>
	</button>
</div>
