<template>
	<div>
		<button
				title=""
				class="swv-button swv-button-trigger btn btn-primary text-white"
				:disabled="disabled || !wallet || connecting || connected"
				@click="(e) => onClick(e)"
		>
			<wallet-icon v-if="wallet" :wallet="wallet"></wallet-icon>
			<span v-text="content"></span>
		</button>
	</div>
</template>

<script>
import WalletIcon from "./WalletIcon.vue";

export default {
	name: "WalletConnectButton",
	components: {
		WalletIcon,
	},
	props: {
		disabled: {
			type: Boolean
		},
		connecting: false,
		wallet: null,
		connect: null,
	},
	data() {
		return {
			connected: false,
		}
	},
	computed: {
		content: function () {
			if (this.connecting) return "Connecting ...";
			if (this.connected) return "Connected";
			if (this.wallet) return "Connect Wallet";
			return "Connect Wallet";
		}
	},
	methods: {
		onClick: function (event) {
			this.$emit('connect')
			return;

			if (event.defaultPrevented || !this.connect) return;
			this.connect().catch((e) => {
				console.error("Unable to connect", e)
			});
		}
	},
}
</script>